
/**
 * @name: 客户管理
 * @author: itmobai
 * @date: 2023-05-31 09:43
 * @description：客户管理
 * @update: 2023-05-31 09:43
 */
import {Component, Vue} from "vue-property-decorator";
import {IMember, IMemberParam} from "@/apis/customer/member/types";
import {ICrudOption} from "@/types/m-ui-crud";
import {userQueryApi} from "@/apis/customer/member";
import {deepCopy} from "@/utils/common";
import {queryUserGradeListApi} from "@/apis/customer/level";

@Component({})
export default class MemberPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  // 表格数据
  tableData: IMember[] = []
  // 表单
  modelForm: Partial<IMember> = {}
  // 查询参数
  queryParam: IMemberParam = {
    page: 1,
    limit: 10,
    userGrade: ""
  }
  // crudOption
  option: ICrudOption = {
    menu: false,
    column: [
      {
        "label": "用户ID",
        "prop": "id",
        "align": "left",
        "width": 180
      },
      {
        "label": "会员名称",
        "prop": "userName",
        "align": "center",
        "width": 150,
        "overHidden": true
      },
      {
        "label": "会员名称",
        "prop": "userKeywords",
        "search": true,
        "hide": true,
        "addHide": true,
        "editHide": true,
        "viewHide": true,
        placeholder: "请输入会员名称/联系方式"
      },
      {
        "label": "手机号",
        "prop": "phone",
        "align": "center",
        "width": 150
      },
      {
        "label": "会员类型",
        "prop": "userTypes",
        "align": "center",
        "width": 120,
        "type": "select",
        "search": true,
        dicData: [
          {
            label: "普通用户",
            value: 1
          },
          {
            label: "达人",
            value: 2
          },
          {
            label: "教练",
            value: 3
          },
          {
            label: "渠道方",
            value: 4
          }
        ]
      },
      {
        "label": "客户来源",
        "prop": "userSource",
        "width": 120,
        "search": true,
        "type": "select",
        "align": "center",
        dicData: [
          {
            label: "达人推荐",
            value: 1
          },
          {
            label: "普通注册",
            value: 2
          },
          {
            label: "渠道方推荐",
            value: 3
          }
        ]
      },
      {
        "label": "绑定渠道方",
        "prop": "channelUserName",
        "overHidden": true,
        "search": true,
        "align": "center",
        "width": 150
      },
      {
        "label": "会员等级",
        "prop": "userGrade",
        "align": "center",
        "type": "select",
        "search": true,
        "width": 150,
        "dicData": [],
        "hide": true
      },
      {
        "label": "会员等级",
        "prop": "userGrade",
        "align": "center",
        "width": 150,
      },
      {
        "label": "钱包余额",
        "prop": "balancePrice",
        "align": "center",
        "width": 120
      },
      {
        "label": "已购商品",
        "prop": "buyGoodsNumber",
        "align": "center",
        "width": 120
      },
      {
        "label": "已购课程",
        "prop": "buyCourseNumber",
        "align": "center",
        "width": 120
      },
      {
        "label": "已报名赛事",
        "prop": "enrollGameNumber",
        "width": 150,
        "align": "center"
      },
      {
        "label": "已报旅游",
        "prop": "enrollTourNumber",
        "width": 120,
        "align": "center"
      },
      {
        "label": "已约网红",
        "prop": "invitedCyberStarNumber",
        "align": "center",
        "width": 120
      },
      {
        "label": "积分",
        "prop": "integral",
        "align": "center",
        "width": 150
      },
      {
        "label": "注册时间",
        "prop": "registerTime",
        "align": "center",
        "width": 150
      },
      {
        "label": "最近登录时间",
        "prop": "lastLoginTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "search": true
      },
      {
        "label": "访问次数",
        "prop": "accesCount",
        "align": "center",
        "width": 120
      }
    ]
  }

  /**
   * 获取会员等级下拉
   */
  getLevelSelect () {
    queryUserGradeListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn('userGrade', {
        dicData: e.map(item => {
          return {
            label: item.gradeName,
            value: item.gradeName
          }
        })
      });
    })
  }

  /**
   * 获取列表
   */
  getList () {
    this.tableLoading = true
    const query: IMemberParam = deepCopy(this.queryParam)
    if (this.queryParam.lastLoginTime && this.queryParam.lastLoginTime.length === 2) {
      query.startTime = this.queryParam.lastLoginTime[0]
      query.endTime = this.queryParam.lastLoginTime[1]
    } else {
      query.startTime = ''
      query.endTime = ''
    }
    delete query.lastLoginTime
    const obj: any = {
      ...query,
      pageParam: {
        page: query.page,
        limit: query.limit
      }
    }
    delete obj.page
    delete obj.limit
    userQueryApi(obj).then(e => {
      this.tableLoading = false
      this.tableTotal = e.total;
      this.tableData = e.list;
    })
  }

  created () {
    this.getList();
  }

  mounted () {
    this.getLevelSelect()
  }
}
