/**
 * @name: 客户管理-会员管理接口
 * @author: itmobai
 * @date: 2023-05-31 09:48
 * @description：客户管理-会员管理接口
 * @update: 2023-05-31 09:48
 */
import {postJ} from "@/request"
import {IMember, IMemberParam} from "@/apis/customer/member/types";
import {IPageRes} from "@/apis/page";

/**
 * 会员管理查询
 * @param params 查询参数
 */
export const userQueryApi = (params: IMemberParam) => postJ<IPageRes<IMember[]>>("/golf/user/query", params)
