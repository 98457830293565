/**
 * @name: 会员等级接口
 * @author: itmobai
 * @date: 2023-05-31 10:27
 * @description：会员等级接口
 * @update: 2023-05-31 10:27
 */
import {get, postJ} from "@/request";
import {ILevel} from "@/apis/customer/level/types";
import {IPageRes} from "@/apis/page";

/**
 * 会员等级下拉列表
 */
export const queryUserGradeListApi = () => postJ<ILevel[]>("/golf/userGradeSetup/queryUserGradeList")
/**
 * 会员等级分页查询
 */
export const userGradeSetupQueryApi = (data: any) => postJ<IPageRes<ILevel[]>>("/golf/userGradeSetup/query", data)
/**
 * 会员等级修改
 * @param data
 */
export const userGradeSetupModifyApi = (data: Partial<ILevel>) => postJ("/golf/userGradeSetup/modify", data)
/**
 * 会员等级删除
 * @param id 会员id
 */
export const userGradeSetupRemoveApi = (id: string) => get("/golf/userGradeSetup/remove/" + id)
/**
 * 会员等级创建
 * @param data
 */
export const userGradeSetupCreateApi = (data: ILevel) => postJ("/golf/userGradeSetup/create", data)
/**
 * 会员等级启用禁用
 * @param data
 */
export const userGradeSetupStatusUpdateApi = (data: Partial<ILevel>) => postJ("/golf/userGradeSetup/statusUpdate", data)
